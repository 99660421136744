import React, {useEffect} from 'react'
import NPSWidget from '../NPSWidget';
import {GA_EVENTS, sendGAEvent} from "../../utils/helper";
import {updateNPSClosedCountFetch} from "./fetch/NPSWidgetFetchUtils";

const WidgetPopup = (props) => {

  const handleClose = () => {
    sendGAEvent(GA_EVENTS.CLICK_NPS_SURVEY_CLOSE, {positly_user_id: props.userId})
    props.closePopup()
    updateNPSCloseCount()
  }

  const updateNPSCloseCount = () => {
    updateNPSClosedCountFetch()
      .then(resp => resp.json())
      .then((json) => {
        console.log('updateNPSCloseCount Response: ', json)
      });
  }

  useEffect(() => {
    props.openPopup('nps-survey-popup', {}, null)
  }, []);

  return (
    <>
      <div id="nps-survey-popup" className="white-popup mfp-hide">
        <div className="relative">
          <i className="fa fa-times cursor-pointer" onClick={handleClose}></i>
          <NPSWidget onSubmit={props.closePopup}/>
        </div>
      </div>
    </>
  );
};

export default WidgetPopup;
