import React, {useState} from 'react'
import {useSelector} from "react-redux";
import {GA_EVENTS, sendGAEvent, triggerLinkedinInsightEvent} from "../utils/helper";
import {LINKEDIN_CONVERSION_IDS} from "../utils/constants";

const ActivityStatusBox = (props) => {
  const {uid: positly_user_id, utm_parameters} = useSelector(state => state.currentUser || {})

  const clickCreateWithGuidedTrack = () => {
    sendGAEvent(GA_EVENTS.CLICK_CREATE_WITH_GT, {positly_user_id})
    triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.CLICK_CREATE_WITH_GT, utm_parameters)
  }

  const handleActivityStatusChange = (e) => {
    const status = e.target.id

    if(typeof props.updateActivityStatus === 'function') {
      props.updateActivityStatus(status)
    }
  }

  const getActivityRecommendationText = () => {
    if(props.activityStatus === 'activity_not_started') {
      return 'To create your survey or study, we recommend using GuidedTrack, our free and powerful builder that ' +
              'integrates seamlessly with Positly.'
    } else if(props.activityStatus === 'activity_started_not_finished') {
      return 'Positly integrates with a wide variety of survey and study creation tools. However, we recommend using ' +
              'GuidedTrack, our free and powerful builder that integrates seamlessly with Positly.'
    }
  }

  const getGTLinkText = () => {
    if(props.activityStatus === 'activity_not_started') {
      return 'Click here to create your survey or study.'
    } else if(props.activityStatus === 'activity_started_not_finished') {
      return 'Click here to give GuidedTrack a try.'
    }
  }

  return (
    <div className='box h-100'>
      <h2 className="font-size-16 pr-3 mb-0 text-required">
        Have you already created the online survey or study you’ll use for this research?
      </h2>

      <div className="my-2 activity-status-select">
        <div className='py-1'>
          <input
            name="activity_status"
            checked={props.activityStatus === 'activity_not_started'}
            onChange={handleActivityStatusChange}
            type="radio"
            id='activity_not_started'
          />
          <label className="po-text" htmlFor='activity_not_started'>
            I still need to create the survey or study and don't have a link for it yet
          </label>
        </div>
        <div className='py-1'>
          <input
            name="activity_status"
            checked={props.activityStatus === 'activity_started_not_finished'}
            onChange={handleActivityStatusChange}
            type="radio"
            id='activity_started_not_finished'
            tabIndex={props.tabIndex}
          />
          <label className="po-text" htmlFor='activity_started_not_finished'>
            I’ve started creating the online survey or study, but I’m not done yet
          </label>
        </div>
        <div className='py-1'>
          <input
            name="activity_status"
            checked={props.activityStatus === 'activity_finished'}
            onChange={handleActivityStatusChange}
            type="radio"
            id='activity_finished'
            tabIndex={props.tabIndex}
          />
          <label className="po-text" htmlFor='activity_finished'>
            I have a link for the survey or study, it’s ready to use
          </label>
        </div>
      </div>

      {(props.showWarning && !props.isActivityStatusValid) && (
        <div className="po-text text-red-soft mt-1 lh-1">
          Required
        </div>
      )}

      {(props.activityStatus === 'activity_not_started' || props.activityStatus === 'activity_started_not_finished' ) && (
        <div className='mt-3 po-text'>
          {getActivityRecommendationText()}&nbsp;

          <a className='link clickable text-underline'
             href='https://www.guidedtrack.com/users/sign_up?utm_source=referral&utm_medium=banner&utm_campaign=positly_banner_1'
             target="_blank"
             rel="noreferrer"
             onClick={clickCreateWithGuidedTrack}>
            {getGTLinkText()}
          </a>
        </div>
      )}
    </div>
  )
}

export default ActivityStatusBox
